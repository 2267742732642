import React, { useEffect, useState } from "react";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Upload,
  DatePicker,
  Form,
  Input,
  Radio,
  Space,
  Select,
  Checkbox,
  Spin,
  notification,
  message
} from "antd";
import * as styles from "./form.module.css";
import regencies from "../../../assets/json/regencies.json";
import masterDistricts from "../../../assets/json/districts.json";
import masterVillages from "../../../assets/json/villages.json";
import { useSelector } from "react-redux";
import { RootState } from "../../../lib";
import type { UploadProps } from "antd";
import { merk_motor, type_motor } from "./motor";

type RequiredMark = boolean | "optional";
const { TextArea } = Input;

const pekerjaan = [
  {
    value: "ASN (Aparatur Sipil Negara) / Karyawan BUMN / BUMD",
    label: "ASN (Aparatur Sipil Negara) / Karyawan BUMN / BUMD",
  },
  {
    value: "Wiraswasta ",
    label: "Wiraswasta ",
  },
  {
    value: "Wirausaha",
    label: "Wirausaha",
  },
  {
    value: "Karyawan Swasta",
    label: "Karyawan Swasta",
  },
  {
    value: "Ojek Online",
    label: "Ojek Online",
  },
  {
    value: "Tidak Bekerja",
    label: "Tidak Bekerja",
  },
  {
    value: "Pelajar/Mahasiswa",
    label: "Pelajar/Mahasiswa",
  },
  {
    value: "Lain-lain",
    label: "Lain-lain",
  },
  {
    value: "Pemberi Kerja",
    label: "Pemberi Kerja",
  },
];

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const FormStep = ({ onNextSelesai }) => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const bengkel = useSelector((state: RootState) => state.formState.bengkel);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [captcha, setCaptcha] = useState();
  const [dataform, setDataform] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedMerkMotor, setSelectedMerkMotor] = useState("Honda");
  const [filterTYpekMotor, setFilterTYpekMotor] = useState([]);
  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

    const openNotificationWithIcon = (type: NotificationType, message, description) => {
      api[type]({
        message: message,
        description:description,
      });
    };

  const fetchDataCaptcha = async () => {
    let url = "/api/webuser/captcha/get";
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ kind: "pengajuan_konversi" }),
    });
    const jsonData = await rawResponse.json();
    if (jsonData.result == 200) {
      setCaptcha(jsonData.data.code);
    }
  };

  const fetchPengajuanSend = async (newobj) => {
    let hasil = newobj;
    const formData = new FormData();
    let filesize = 0;
    //console.log(newobj)
    for (let key in hasil) {
      if (hasil[key].file) {
        filesize += hasil[key].file.size;
        formData.append(key, hasil[key].file, hasil[key].file.name);
        formData.append(key + "_data", JSON.stringify(hasil[key].fileList[0]));
      } else {
        formData.append(key, hasil[key]);
      }
    }
    const maxFileSize = 9 * 1024 * 1024; // 2 * 1024MB limit
    //console.log('maxFileSize ', maxFileSize)
    //console.log('filesize ', filesize)
    if(filesize > maxFileSize){
      setLoading(false);
      openNotificationWithIcon('error', 'File to large', 'Please upload file that has max size of 2 megabytes or less.')
      return;
    }
    
    let url = "/api/webuser/pengajuan/send/upload";
    const rawResponse = await fetch(url, {
      method: "POST",
      body: formData,
    });
    try {
      const jsonData = await rawResponse.json();
      if (jsonData.result == 200) {
        onNextSelesai(jsonData.data.code);
      }
    } catch (error) {
      message.error("upload failed.");
    }
    
    setLoading(false);
  };

  /*const fetchPengajuanSend = async (data) => {
    let url = "/api/webuser/pengajuan/send";
    const rawResponse = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const jsonData = await rawResponse.json();
    if (jsonData.result == 200) {
      onNextSelesai(jsonData.data.code);
    }
    setLoading(false);
  };*/

  useEffect(() => {
    form.setFieldValue("name_perusahaan", bengkel?.company);
    form.setFieldValue("name_bengkel", bengkel?.name.toUpperCase());
    form.setFieldValue("alamat_bengkel", bengkel?.address);
    form.setFieldValue("bengkel_id", bengkel?.uuid);

    let filter = type_motor.filter((d) => {
      if (d.group == selectedMerkMotor) {
        return true;
      }
      return false;
    });
    setFilterTYpekMotor(filter);
  }, [bengkel]);

  useEffect(() => {
    if (captcha && dataform) {
      console.log("Success:", dataform);
      let newobj = Object.assign({}, dataform);
      newobj.code = captcha;
      fetchPengajuanSend(newobj);
    }
  }, [captcha]);

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const onFinish = (values: any) => {
    setLoading(true);
    //values.bengkel = bengkel;
    //setDataform(values);
    let hasil = values;
    hasil.kedatangan = hasil.kedatangan.toISOString();
    hasil.tanggal_lahir = hasil.tanggal_lahir.toISOString();
    setDataform(hasil);
    console.log(hasil);
    fetchDataCaptcha();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const props: UploadProps = {
    multiple: false,
    accept: ".png,.jpg,.jpeg",
    onRemove: (file) => {
      //
    },
    beforeUpload: (file) => {
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    //fileList,
  };

  const handleChangeMerkMotor = (m) => {
    setSelectedMerkMotor(m);
    let filter = type_motor.filter((d) => {
      if (d.group == m) {
        return true;
      }
      return false;
    });
    setFilterTYpekMotor(filter);
  };

  if (loading) {
    return (
      <>
        {contextHolder}
        <div className={`${styles.center}`}>
          <Spin size="large" />
        </div>
      </>
    );
  }

  return (
    <>
    {contextHolder}
    <Form
      form={form}
      className={`${styles.sform}`}
      layout="vertical"
      initialValues={{ requiredMarkValue: requiredMark }}
      onValuesChange={onRequiredTypeChange}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <div
        style={{ marginTop: "40px", marginBottom: "20px" }}
        className={`${styles.flexJustify}`}
      >
        <div>
          <div style={{ fontSize: "20px", fontWeight: 500 }}>
            DATA PEMILIK SEPEDA MOTOR
          </div>
        </div>
        <div></div>
      </div>

      <div className={`${styles.grid3}`}>
        <Form.Item
          name="name"
          label="Nama Lengkap"
          required
          tooltip="Masukan Nama Lengkap"
          rules={[
            { required: true, message: "Silahkan Masukan Nama Lengkap!" },
          ]}
        >
          <Input placeholder="Masukan Nama Lengkap" />
        </Form.Item>
        <Form.Item
          name="nik"
          label="NIK"
          tooltip={{ title: "Masukan NIK", icon: <InfoCircleOutlined /> }}
          rules={[{ required: true, message: "Silahkan Masukan NIK!" }]}
        >
          <Input placeholder="NIK" />
        </Form.Item>
        <Form.Item
          name="file_ktp"
          rules={[{ required: true, message: "Silahkan Masukan Foto KTP!" }]}
          label="KTP"
        >
          <Upload {...props} fileList={form.getFieldValue('file_ktp')?.fileList}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
      </div>

      <div className={`${styles.grid4}`}>
        <Form.Item
          rules={[{ required: true, message: "Silahkan Masukan Gender!" }]}
          initialValue="pria"
          label="Jenis kelamin"
          name="gender"
        >
          <Radio.Group>
            <Radio value="pria">Pria</Radio>
            <Radio value="wanita">Wanita</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="pekerjaan"
          rules={[{ required: true, message: "Silahkan Masukan Peerjaan!" }]}
          label="Pekerjaan"
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Pilih Pekerjaan"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={pekerjaan}
          />
        </Form.Item>
        <Form.Item
          name="tempat_lahir"
          label="Tempat Lahir"
          rules={[
            { required: true, message: "Silahkan Masukan Tempat Lahir!" },
          ]}
          tooltip="Masukan Tempat Lahir"
        >
          <Input placeholder="Tempat Lahir" />
        </Form.Item>
        <Form.Item
          name="tanggal_lahir"
          label="Tanggal Lahir"
          rules={[
            { required: true, message: "Silahkan Masukan Tanggal Lahir!" },
          ]}
          tooltip={{
            title: "Masukan Tanggal Lahir",
            icon: <InfoCircleOutlined />,
          }}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      </div>

      <div className={`${styles.grid2}`}>
        <Form.Item
          rules={[{ required: true, message: "Silahkan Masukan No Telp!" }]}
          name="telp"
          label="No Telp"
          tooltip="Masukan No Telp"
          extra="Masukan nomor WA yang aktif"
        >
          <Input addonBefore="+62" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            { type: "email", message: "Silahkan Masukan format Email!" },
            { required: true, message: "Silahkan Masukan Email!" },
          ]}
          tooltip={{ title: "Masukan Email", icon: <InfoCircleOutlined /> }}
          extra="Informasi pendaftaran akan dikirim lewat email"
        >
          <Input />
        </Form.Item>
      </div>

      <div
        style={{ fontSize: "20px", fontWeight: "600", marginBottom: "20px" }}
      >
        Alamat Sesuai KTP
      </div>

      <div className={`${styles.grid3}`}>
        <Form.Item
          name="kota"
          rules={[{ required: true, message: "Silahkan Masukan Kota!" }]}
          label="Kota"
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Pilih Kota"
            optionFilterProp="children"
            onChange={(value, option) => {
              let dd = masterDistricts.filter(
                (d) => d.regency_id == option["id"],
              );
              setDistricts(dd);
              //console.log('value ', dd)
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={regencies}
          />
        </Form.Item>

        <Form.Item
          name="kecamatan"
          rules={[{ required: true, message: "Silahkan Masukan Kecamatan!" }]}
          label="Kecamatan"
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Pilih Kecamatan"
            optionFilterProp="children"
            onChange={(value, option) => {
              let dd = (masterVillages as Array<any>).filter(
                (d) => d.district_id == option["id"],
              );
              setVillages(dd);
              //console.log('value ', dd)
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={districts}
          />
        </Form.Item>

        <Form.Item
          name="Kelurahan"
          rules={[{ required: true, message: "Silahkan Masukan Kelurahan!" }]}
          label="Kelurahan"
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Pilih Kelurahan"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={villages}
          />
        </Form.Item>
      </div>

      <div className={`${styles.grid31}`}>
        <Form.Item
          name="alamat"
          label="Alamat"
          required
          tooltip="Masukan Nama Lengkap"
        >
          <Input placeholder="Alamat" />
        </Form.Item>

        <Form.Item
          name="kodepos"
          label="Kodepos"
          tooltip={{ title: "Masukan NIK", icon: <InfoCircleOutlined /> }}
        >
          <Input placeholder="Kode Pos" />
        </Form.Item>
      </div>

      <div
        style={{ marginTop: "40px", marginBottom: "20px" }}
        className={`${styles.flexJustify}`}
      >
        <div>
          <div style={{ fontSize: "20px", fontWeight: 500 }}>
            SPESIFIKASI SEPEDA MOTOR
          </div>
        </div>
        <div></div>
      </div>

      <div className={`${styles.grid2}`}>
        <Form.Item name="merk" initialValue="Honda" label="Merk">
          {/*<Input style={{ color: "black" }} />*/}
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Pilih Merk Motor"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={merk_motor}
            onChange={handleChangeMerkMotor}
          />
        </Form.Item>
        <Form.Item
          name="type_motor"
          rules={[{ required: true, message: "Silahkan Masukan Type MOtor!" }]}
          label="Type Motor"
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Pilih Type Motor"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={filterTYpekMotor}
          />
        </Form.Item>
      </div>

      <div className={`${styles.grid3}`}>
        <Form.Item
          name="no_polisi"
          label="Nomor Polisi"
          rules={[{ required: true, message: "Silahkan Masukan Nomor Polisi" }]}
          tooltip="Masukan Nama Lengkap"
        >
          <Input placeholder="Masukan Nomor Polisi" />
        </Form.Item>
        <Form.Item
          name="no_rangka"
          label="Nomor Rangka"
          rules={[{ required: true, message: "Silahkan Masukan Nomor Rangka" }]}
          tooltip={{
            title: "Masukan Nomor Rangka",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input placeholder="Nomor Rangka" />
        </Form.Item>
        <Form.Item
          name="no_mesin"
          label="Nomor Mesin"
          rules={[{ required: true, message: "Silahkan Masukan Nomor Mesin" }]}
          tooltip={{
            title: "Masukan Nomor Mesin",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input placeholder="No Mesin" />
        </Form.Item>
      </div>

      <div className={`${styles.grid2}`}>
        <Form.Item
          name="surat_kendaraan"
          initialValue="pribadi_sesuai_ktp"
          label="Surat Kendaraan"
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="pribadi_sesuai_ktp">
                Atas nama pribadi (sesuai KTP)
              </Radio>
              <Radio value="an_orang_lain">Atas nama orang lain</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="status_pajak"
          initialValue="status_pajak_aktif"
          rules={[{ required: true, message: "Silahkan Pilih Status Pajak!" }]}
          label="Status Pajak Aktif"
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="status_pajak_aktif">Ya</Radio>
              <Radio value="status_pajak_tidak_aktif">Tidak</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </div>

      <div
        style={{ marginTop: "40px", marginBottom: "20px" }}
        className={`${styles.flexJustify}`}
      >
        <div>
          <div style={{ fontSize: "20px", fontWeight: 500 }}>
            DOKUMENTASI SEPEDA MOTOR
          </div>
        </div>
        <div></div>
      </div>

      <div className={`${styles.grid2}`}>
        <Form.Item
          rules={[
            { required: true, message: "Silahkan Masukan Foto Tampak Depan!" },
          ]}
          name="file_tampak_depan"
          label="Tampak Depan"
        >
          <Upload fileList={form.getFieldValue('file_tampak_depan')?.fileList} {...props}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="file_tampak_belakang"
          rules={[
            {
              required: true,
              message: "Silahkan Masukan Foto Tampak Belakang!",
            },
          ]}
          label="Tampak Belakang"
        >
          <Upload fileList={form.getFieldValue('file_tampak_belakang')?.fileList} {...props}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
      </div>

      <div className={`${styles.grid2}`}>
        <Form.Item
          rules={[
            { required: true, message: "Silahkan Masukan Foto Samping Kanan!" },
          ]}
          name="file_samping_kanan"
          label="Samping Kanan"
        >
          <Upload fileList={form.getFieldValue('file_samping_kanan')?.fileList} {...props}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="file_samping_kiri"
          rules={[
            { required: true, message: "Silahkan Masukan Foto Samping Kiri!" },
          ]}
          label="Samping Kiri"
        >
          <Upload fileList={form.getFieldValue('file_samping_kiri')?.fileList} {...props}>
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
      </div>

      <div
        style={{ fontSize: "20px", fontWeight: "600", marginBottom: "20px" }}
      >
        Bengkel
      </div>

      <div className={`${styles.grid2}`}>
        <Form.Item name="name_perusahaan" label="Nama Perusahaan" required>
          <Input style={{ color: "black" }} disabled />
        </Form.Item>
        <Form.Item name="name_bengkel" label="Nama Bengkel" required>
          <Input style={{ color: "black" }} disabled />
        </Form.Item>
      </div>

      <div className={`${styles.grid41}`}>
        <Form.Item name="alamat_bengkel" label="Alamat" required>
          <Input style={{ color: "black" }} disabled />
        </Form.Item>
        <Form.Item
          name="kedatangan"
          rules={[
            {
              required: true,
              message: "Silahkan Masukan  Rencana Datang Ke Bengkel",
            },
          ]}
          label="Tanggal Rencana Datang Ke Bengkel"
          tooltip={{
            title: "Masukan Tanggal Rencana Datang Ke Bengkel",
            icon: <InfoCircleOutlined />,
          }}
        >
          <DatePicker format="DD-MM-YYYY" />
        </Form.Item>
      </div>

      <div
        style={{ marginTop: "40px", marginBottom: "20px" }}
        className={`${styles.flexJustify}`}
      >
        <div>
          <div style={{ fontSize: "20px", fontWeight: 500 }}>
            PERNYATAAN PERSETUJUAN KONVERSI KBBBM KE KBLBB
          </div>
        </div>
        <div></div>
      </div>

      <div className={`${styles.grid1}`}>
        <Form.Item
          label="Syarat dan Ketentuan"
          tooltip={{ title: "Masukan NIK", icon: <InfoCircleOutlined /> }}
        >
          <TextArea
            value={`S&K konversi KBBBM ke KBLBB`}
            rows={10}
            placeholder="S&K konversi KBBBM ke KBLBB"
          />
        </Form.Item>
      </div>

      <div className={`${styles.grid1}`}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Silahkan Pilih Dahulu!",
            },
          ]}
          name="seluruh_data_benar"
          valuePropName="checked"
        >
          <Checkbox>
            Saya menyatakan bahwa seluruh data dan dokumen yang saya berikan
            adalah benar dan dapat diverifikasi
          </Checkbox>
        </Form.Item>
      </div>

      <div className={`${styles.grid1}`}>
        <Form.Item
          name="bersedia_melakukan_konversi"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message: "Silahkan Pilih Dahulu!",
            },
          ]}
        >
          <Checkbox>
            Saya bersedia melakukan konversi KBBBM ke KBLBB di bengkel Nagara
            Konversi dan memberikan kuasa untuk melakukan proses konversi sampai
            pengurusan dokumen kepada Nagara
          </Checkbox>
        </Form.Item>
      </div>

      <div className={`${styles.grid1}`}>
        <Form.Item
          name="menyetujui"
          rules={[
            {
              required: true,
              message: "Silahkan Pilih Dahulu!",
            },
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            Saya telah membaca dan menyetujui <a>Syarat & Ketentuan</a> konversi
            KBBBM ke KBLBB dan merupakan satu kesatuan dan bagian yang tidak
            terpisahkan dengan formulir ini
          </Checkbox>
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    </>
  );
};

{
  /*value={`PERSYARATAN YANG HARUS DIBAWA
          Pemohon diharuskan memiliki dokumen administrasi sebagai berikut:
          - KTP
          - BPKB (Buku Pemilik Kendaraan Bermotor)
          - STNK (Surat Tanda Nomor Kendaraan)
          
          Untuk Syarat Motor Pemohon adalah sebagai berikut:
          - Kondisi fisik motor masih beroperasi
          - Identitas pada STNK harus sesuai dengan KTP
        - Kapasitas mesin maksimal 160 CC.`}*/
}
