import React, { useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Row,
  Col,
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  Space,
  Tag,
  Table,
  Popconfirm,
  message,
  Spin,
  notification
} from "antd";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import regencies from "../../../assets/json/regencies.json";
import * as styles from "./form.module.css";
const { TextArea } = Input;
type RequiredMark = boolean | "optional";
import type { ColumnsType } from "antd/es/table";
import { ModalTeknisi } from "./modal-teknisi";
import { IDaftarBengkel } from "../../../lib";
import { produce } from "immer";
import dayjs from "dayjs";
import type { UploadProps } from "antd";
import { SuccessStep } from "./submit";

interface DataType {
  key: string;
  name: string;
  no: number;
  nomor_sertifikat: string;
  file_serkom: string;
  mulai_berlaku: string;
  akhir_berlaku: string;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const BengkelDaftarPage = () => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [dataTeknisi, setDataTeknisi] = useState([]);
  const [finish, setFinish] = useState(false);
  const [isModalTeknisiOpen, setIsModalTeknisiOpen] = useState(false);
  //const [dataBengkel, setDataBengkel] = useState<IDaftarBengkel>();

  const openNotificationWithIcon = (type: NotificationType, message, description) => {
    api[type]({
      message: message,
      description:description,
    });
  };

  const confirm = (record) => {
    console.log(record);
    message.success("Data Teknisi terhapus");

    let jumlah = -1;
    const nextState = produce(dataTeknisi, (draft) => {
      let idx = draft.findIndex((d) => d.uuid == record.uuid);
      if (idx > -1) {
        draft.splice(idx, 1);
      }
      jumlah = draft.length;
    });
    setDataTeknisi(nextState);

    if (jumlah == 0) {
      const fields = form.getFieldsValue();
      const nextState2 = produce(fields, (draft) => {
        draft["dataTeknisi"] = "";
      });
      form.setFieldsValue(nextState2);
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "No",
      key: "no",
      render: (_, record, idx) => <Space size="middle">{`${idx + 1}`}</Space>,
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Nomor Sertifikat Kompetensi",
      dataIndex: "nomor_serkom",
      key: "nomor_serkom",
    },
    {
      title: "File Serkom",
      dataIndex: "file_serkom",
      key: "file_serkom",
      render: (value) => <Space size="middle">{value.fileList[0].name}</Space>,
    },
    {
      title: "Masa Berlaku",
      key: "masa_berlaku",
      render: (_, record) => (
        <Space size="middle">
          {`${new Intl.DateTimeFormat(["ban", "id"]).format(
            new Date(record.mulai_serkom),
          )} - ${new Intl.DateTimeFormat(["ban", "id"]).format(
            new Date(record.akhir_serkom),
          )}`}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Popconfirm
          title="Hapus Data Teknisi"
          description="Anda Yakin akan menghapus Data Teknisi?"
          onConfirm={() => confirm(record)}
          okText="Yes"
          cancelText="No"
        >
          <Space size="middle">
            <a>Delete</a>
          </Space>
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    const cat = localStorage.getItem("dataBengkel");
    if (cat) {
      let json = JSON.parse(cat);
      //setDataBengkel(json);

      const fields = form.getFieldsValue();
      const nextState = produce(fields, (draft) => {
        for (let key in json) {
          if (key == "tanggal_berlaku_sertifikat_bengkel_instalasi_listrik") {
            draft[key] = dayjs(json[key]);
          } else {
            draft[key] = json[key];
          }
        }
      });
      form.setFieldsValue(nextState);
    }
  }, []);

  const [requiredMark, setRequiredMarkType] =
    useState<RequiredMark>("optional");

  const onRequiredTypeChange = ({
    requiredMarkValue,
  }: {
    requiredMarkValue: RequiredMark;
  }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const onFieldsChange = (values: any) => {
    //console.log("formName:", formName);
    //console.log("onFieldsChange:", values);
    if (values.length > 0) {
      if (
        values[0].name[0] == "nib_file" ||
        values[0].name[0] == "npwp_file" ||
        values[0].name[0] == "kswp_file" ||
        values[0].name[0] == "sertifikat_bengkel_instalasi_listrik_file" ||
        values[0].name[0] == "file_sut" ||
        values[0].name[0] == "file_srut" ||
        values[0].name[0] == "dokumen_kelengkapan" ||
        values[0].name[0] == "dokumen_sertifikat" ||
        values[0].name[0] == "surat_permohonan" ||
        values[0].name[0] == "file_buku_rekening"
      ) {
        console.log("di dalam change ", values);
        return;
      }

      const cat = localStorage.getItem("dataBengkel");
      let ds = {};
      if (cat) {
        let json = JSON.parse(cat);
        ds = json;
      }

      ds[values[0].name[0]] = values[0].value;
      //console.log(ds)
      localStorage.setItem("dataBengkel", JSON.stringify(ds));
    }
    //store.dispatch(setBengkel(record));
  };

  const onFinish = async (values: any) => {
    console.log("Success:", values);
    console.log("dataTeknisi:", dataTeknisi);
    setLoading(true);
    let filesize = 0;
    const formData = new FormData();
    for (let key in values) {
      if (values[key].file) {
        filesize += values[key].file.size;
        formData.append(key, values[key].file, values[key].file.name);
        formData.append(key + "_data", JSON.stringify(values[key].fileList[0]));
      } else {
        formData.append(key, values[key]);
      }
    }

    const maxFileSize = 25 * 1024 * 1024; // 2 * 1024MB limit
    //console.log('maxFileSize ', maxFileSize)
    //console.log('filesize ', filesize)
    if(filesize > maxFileSize){
      setLoading(false);
      openNotificationWithIcon('error', 'File to large', 'Please upload file that has max size of 2 megabytes or less.')
      return;
    }

    //formData.append(`data_teknisi`, JSON.stringify(dataTeknisi));
    let data_teknisi = [];
    for (let i = 0; i < dataTeknisi.length; i++) {
      let obj = dataTeknisi[i];
      for (let key in obj) {
        if (obj[key].file) {
          formData.append(`${key}_${i}`, obj[key].file, obj[key].file.uid);
          let p = {};
          p[key] = obj[key].fileList[0];
          data_teknisi.push(p);
        } else {
          //formData.append(`${key}_${i}`, obj[key]);
          let p = {};
          p[key] = obj[key];
          data_teknisi.push(p);
        }
      }
    }
    formData.append(`data_teknisi`, JSON.stringify(data_teknisi));

    fetch("/api/webuser/bengkel/upload", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then(() => {
        //setFileList([]);
        message.success("send successfully.");
        localStorage.removeItem("dataBengkel");
        setDataTeknisi([]);
        form.resetFields();
        setFinish(true);
        //form.resetFields();
      })
      .catch(() => {
        message.error("upload failed.");
      })
      .finally(() => {
        setLoading(false);
      });

    //
    /*const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as File, file.uid);
    });
    if (hasil.length == 0) {
      console.log(hasil, 'hasilq');
      message.error('Fields Empty.');
      return;
    }
    formData.append('data', JSON.stringify(hasil));*/
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const props: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      //
    },
    beforeUpload: (file) => {
      return false;
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    //fileList,
  };

  const addDataForm = (value: any) => {
    const nextState = produce(dataTeknisi, (draft) => {
      draft.push(value);
    });
    setDataTeknisi(nextState);

    const fields = form.getFieldsValue();
    const nextState2 = produce(fields, (draft) => {
      draft["dataTeknisi"] = "ok";
    });
    form.setFieldsValue(nextState2);
  };

  if (finish) {
    return (
      <>
      {contextHolder}
      <div className={`${styles.center}`}>
        <SuccessStep code={"NOMERNYA"} />
      </div>
      </>
    );
  }
  if (loading) {
    return (
      <div className={`${styles.center}`}>
        <Spin size="large" />
      </div>
    );
  }
  return (
    <div className={`${styles.body}`}>
      {contextHolder}
      <Form
        form={form}
        className={`${styles.sform}`}
        name="bengkel"
        layout="vertical"
        initialValues={{ jenis_bengkel: "perorangan" }}
        onFieldsChange={onFieldsChange}
        onValuesChange={onRequiredTypeChange}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Typography.Title level={2}>
          Form Pendaftaran Bengkel Konversi
        </Typography.Title>
        <Typography.Title level={4}>
          Silahkan lengkapi form di bawah ini dengan benar
        </Typography.Title>
        <div>
          Silahkan unduh surat permohonan penunjukan bengkel konversi berikut,{" "}
          <a href="">klik disini</a>.
        </div>
        <Divider />
        <Typography.Title level={4}>Jenis Bengkel</Typography.Title>

        <div className={`${styles.grid2}`}>
          <Form.Item
            name="jenis_bengkel"
            rules={[
              { required: true, message: "Silahkan Masukan Jenis Bengkel!" },
            ]}
            tooltip="Jenis Bengkel"
            label="Jenis Bengkel"
          >
            <Radio.Group>
              <Radio value="perorangan"> Perorangan </Radio>
              <Radio value="usaha"> Badan Usaha </Radio>
            </Radio.Group>
          </Form.Item>
        </div>

        <Typography.Title level={4}>Data Pengurus</Typography.Title>
        <div className={`${styles.grid2}`}>
          <Form.Item
            label="Nama Pengurus"
            name="nama_pengurus"
            rules={[
              { required: true, message: "Silahkan Masukan Nama Pengurus!" },
            ]}
            tooltip="Nama Pengurus"
          >
            <Input placeholder="Nama Pengurus" />
          </Form.Item>
          <Form.Item
            label="NIK Pengurus"
            name="nik_pengurus"
            rules={[
              { required: true, message: "Silahkan Masukan NIK Pengurus!" },
            ]}
            tooltip="NIK Pengurus"
          >
            <Input placeholder="NIK Pengurus" />
          </Form.Item>
        </div>

        <div className={`${styles.grid1}`}>
          <Form.Item
            label="Alamat Pengurus"
            name="alamat_pengurus"
            rules={[
              { required: true, message: "Silahkan Masukan Alamat Pengurus!" },
            ]}
            tooltip={{ title: "Alamat Pengurus", icon: <InfoCircleOutlined /> }}
          >
            <TextArea rows={5} placeholder="Alamat Pengurus" />
          </Form.Item>
        </div>

        <Typography.Title level={4}>Data Bengkel</Typography.Title>

        <div className={`${styles.grid2}`}>
          <Form.Item
            label="Nama Bengkel"
            name="nama_bengkel"
            rules={[
              { required: true, message: "Silahkan Masukan Nama Bengkel!" },
            ]}
            tooltip="Nama Bengkel"
          >
            <Input placeholder="Nama Bengkel" />
          </Form.Item>
          <Form.Item
            label="Nama Perusahaan"
            name="nama_perusahaan_bengkel"
            rules={[
              {
                required: true,
                message: "Silahkan Masukan Nama Perusahaan Bengkel!",
              },
            ]}
            tooltip="Nama Perusahaan"
          >
            <Input placeholder="Nama Perusahaan" />
          </Form.Item>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            name="kota_bengkel"
            rules={[{ required: true, message: "Silahkan Masukan Kota!" }]}
            label="Kota"
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Pilih Kota"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input)
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={regencies}
            />
          </Form.Item>
          <Form.Item
            name="kodepos_bengkel"
            label="Kodepos"
            rules={[
              { required: true, message: "Silahkan Masukan Kodepos Bengkel!" },
            ]}
            tooltip="Kodepos"
          >
            <Input placeholder="Kodepos" />
          </Form.Item>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            name="latitude_bengkel"
            label="Latitude"
            rules={[
              { required: true, message: "Silahkan Masukan Latitude Bengkel!" },
            ]}
            tooltip="Latitude"
          >
            <Input placeholder="Latitude" />
          </Form.Item>
          <Form.Item
            name="longitude_bengkel"
            label="Longitude"
            rules={[
              {
                required: true,
                message: "Silahkan Masukan Longitude Bengkel!",
              },
            ]}
            tooltip="Longitude"
          >
            <Input placeholder="Longitude" />
          </Form.Item>
        </div>
        <div className={`${styles.grid1}`}>
          <Form.Item
            name="alamat_bengkel"
            label="Alamat"
            rules={[
              { required: true, message: "Silahkan Masukan Alamat Bengkel!" },
            ]}
            tooltip={{ title: "Alamat", icon: <InfoCircleOutlined /> }}
          >
            <TextArea rows={5} placeholder="Alamat" />
          </Form.Item>
        </div>

        <Typography.Title level={4}>Dokumen-dokumen</Typography.Title>

        <div className={`${styles.grid2}`}>
          <div>
            <Form.Item
              name="nib"
              label="Nomor Induk Berusaha (NIB)"
              rules={[{ required: true, message: "Silahkan Masukan NIB!" }]}
              tooltip="This is a required field"
            >
              <Input placeholder="Nomor Induk Berusaha (NIB)" />
            </Form.Item>
            <Form.Item
              label="File NIB"
              name="nib_file"
              rules={[{ required: true, message: "Silahkan Upload NIB!" }]}
              tooltip="This is a required field"
            >
              <Upload fileList={form.getFieldValue('nib_file')?.fileList} {...props}>
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
          </div>

          <div>
            <Form.Item
              label="NPWP"
              name="npwp"
              rules={[{ required: true, message: "Silahkan Masukan NPWP!" }]}
              tooltip="This is a required field"
            >
              <Input placeholder="NPWP" />
            </Form.Item>
            <Form.Item
              label="Dokumen Nomor Pokok Wajib Pajak (NPWP)"
              name="npwp_file"
              rules={[{ required: true, message: "Silahkan Upload NPWP!" }]}
              tooltip="This is a required field"
            >
              <Upload fileList={form.getFieldValue('npwp_file')?.fileList} {...props}>
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
          </div>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            label="Dokumen Konfirmasi Status Wajib Pajak (KSWP)"
            name="kswp_file"
            rules={[{ required: true, message: "Silahkan Upload KSWP!" }]}
            tooltip="This is a required field"
          >
            <Upload fileList={form.getFieldValue('kswp_file')?.fileList} {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="sertifikat_bengkel_instalasi_listrik"
            label="Nomor Sertifikat Bengkel Instalasi Penggerak Motor Listrik"
            rules={[
              {
                required: true,
                message:
                  "Silahkan Masukan Nomor Sertifikat Bengkel Instalasi Penggerak Motor Listrik!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Input placeholder="Nomor Sertifikat Bengkel Instalasi" />
          </Form.Item>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            name="sertifikat_bengkel_instalasi_listrik_file"
            label="File Sertifikat Bengkel Instalasi Penggerak Motor Listrik"
            rules={[
              {
                required: true,
                message:
                  "Silahkan Upload Sertifikat Bengkel Instalasi Penggerak Motor Listrik!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Upload fileList={form.getFieldValue('sertifikat_bengkel_instalasi_listrik_file')?.fileList} {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="tanggal_berlaku_sertifikat_bengkel_instalasi_listrik"
            label="Tanggal Berlaku Sertifikat Bengkel Instalasi Penggerak Motor Listrik"
            rules={[
              {
                required: true,
                message:
                  "Silahkan Masukan Tanggal berlaku Sertifikat Bengkel Instalasi Penggerak Motor Listrik!",
              },
            ]}
            tooltip="This is a required field"
          >
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            label="File SUT yang Sudah Pernah Dikerjakan"
            name="file_sut"
            rules={[
              {
                required: true,
                message:
                  "Silahkan Upload File SUT yang Sudah Pernah Dikerjakan!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Upload fileList={form.getFieldValue('file_sut')?.fileList} {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="File SRUT yang Sudah Pernah Dikerjakan"
            name="file_srut"
            rules={[
              {
                required: true,
                message:
                  "Silahkan Upload File SRUT yang Sudah Pernah Dikerjakan!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Upload fileList={form.getFieldValue('file_srut')?.fileList} {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            label="Dokumen Kelengkapan Part/Peralatan Konversi "
            name="dokumen_kelengkapan"
            rules={[
              {
                required: true,
                message:
                  "Silahkan Upload Dokumen Kelengkapan Part/Peralatan Konversi!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Upload fileList={form.getFieldValue('dokumen_kelengkapan')?.fileList} {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Dokumen Sertifikat Pelatihan Tenaga Teknis "
            name="dokumen_sertifikat"
            rules={[
              {
                required: true,
                message:
                  "Silahkan Upload Dokumen Sertifikat Pelatihan Tenaga Teknis!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Upload fileList={form.getFieldValue('dokumen_sertifikat')?.fileList} {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            label="File Surat Permohonan Penunjukan Bengkel Konversi "
            rules={[
              {
                required: true,
                message:
                  "Silahkan Upload File Surat Permohonan Penunjukan Bengkel Konversi!",
              },
            ]}
            name="surat_permohonan"
            tooltip="This is a required field"
          >
            <Upload fileList={form.getFieldValue('surat_permohonan')?.fileList} {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <Typography.Title level={4}>Rekening Bank</Typography.Title>

        <div className={`${styles.grid2}`}>
          <Form.Item
            label="Nama Bank"
            name="nama_bank"
            rules={[{ required: true, message: "Silahkan Masukan Nama Bank!" }]}
            tooltip="This is a required field"
          >
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="Nomor Rekening"
            name="nomor_rekening"
            rules={[
              { required: true, message: "Silahkan Masukan Nomor Rekening!" },
            ]}
            tooltip="This is a required field"
          >
            <Input placeholder="input placeholder" />
          </Form.Item>
        </div>

        <div className={`${styles.grid2}`}>
          <Form.Item
            label="Nama Rekening"
            name="nama_rekening"
            rules={[
              { required: true, message: "Silahkan Masukan Nama Rekening!" },
            ]}
            tooltip="This is a required field"
          >
            <Input placeholder="input placeholder" />
          </Form.Item>
          <Form.Item
            label="File Buku Rekening"
            name="file_buku_rekening"
            rules={[
              {
                required: true,
                message: "Silahkan Upload File Buku Rekening!",
              },
            ]}
            tooltip="This is a required field"
          >
            <Upload fileList={form.getFieldValue('file_buku_rekening')?.fileList} {...props}>
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        </div>

        <Typography.Title level={4}>Informasi Akun</Typography.Title>

        <div className={`${styles.grid2}`}>
          <Form.Item
            label="No. Telepon"
            name="phone"
            rules={[
              {
                required: true,
                message: "Silahkan Masukan No. Telepon / WA yang aktif!",
              },
            ]}
            tooltip="This is a required field"
            extra="Masukan nomor WA yang aktif"
          >
            <Input placeholder="No. Telepon" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: "email", message: "Silahkan Masukan format Email!" },
              { required: true, message: "Silahkan Masukan Email!" },
            ]}
            tooltip="This is a required field"
          >
            <Input placeholder="email" />
          </Form.Item>
        </div>

        <div className={`${styles.tenagaTeknisTitle}`}>
          <Typography.Title level={4}>Tenaga Teknis</Typography.Title>
          <Button
            onClick={() => {
              setIsModalTeknisiOpen(true);
            }}
            type="primary"
          >
            + Tambah
          </Button>
        </div>

        <Form.Item
          name="dataTeknisi"
          hidden={true}
          rules={[{ required: true, message: "Silahkan Masukan dataTeknisi!" }]}
          tooltip="This is a required field"
        >
          <Input placeholder="dataTeknisi" />
        </Form.Item>
        <div
          style={{
            color: "red",
            display: dataTeknisi.length == 0 ? "block" : "none",
          }}
        >
          Silahkan Maukan Data Teknisi
        </div>
        <Table columns={columns} dataSource={dataTeknisi} />

        <div className={`${styles.grid1}`}>
          <Form.Item
            label="Syarat dan Ketentuan"
            tooltip={{ title: "Masukan NIK", icon: <InfoCircleOutlined /> }}
          >
            <TextArea
              value={`Sebagai pemilik/pengurus sah Bengkel Konversi tersebut di atas, menyatakan:
            1. kebenaran keterangan, surat, bukti, dan/atau dokumen lainnya yang disampaikan dalam rangka penetapan sebagai Bengkel Konversi;
            2. berkomitmen untuk ikut serta pada program Konversi;
            3. sepakat bahwa Biaya Konversi ditetapkan paling tinggi sebesar Rp17.000.000,00 (tujuh belas juta rupiah) untuk sepeda motor dengan penggerak motor bakar kapasitas mesin 110 cc (seratus sepuluh sentimeter kubik) sampai dengan 150 cc (seratus dua puluh lima sentimeter kubik); dan
            4. memiliki kemampuan teknis dan keuangan untuk memastikan keberlangsungan usaha Bengkel Konversi lebih dari 3 (tiga) tahun sejak pemberian Bantuan.`}
              rows={10}
              placeholder="maxLength is 6"
            />
          </Form.Item>
        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>

      <ModalTeknisi
        isModalOpen={isModalTeknisiOpen}
        setIsModalOpen={setIsModalTeknisiOpen}
        setData={addDataForm}
      />
    </div>
  );
};
